<template>
  <div class="order">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }"
        ><i class="el-icon-s-home"></i
      ></el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/user' }">会员中心</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/order' }">我的订单</el-breadcrumb-item>
      <el-breadcrumb-item>订单详情</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="main">
      <div class="order-title">订单信息</div>
      <div class="order-info">
        <div class="order-delivery">
          <div class="order-cell">
            <div>收货地址:</div>
            <div>{{detailInfo.user_name}}, {{detailInfo.userid}},{{detailInfo.user_addr}}</div>
          </div>
          <div class="order-cell">
            <div>运送方式:</div>
            <div>快递</div>
          </div>
        </div>
        <div class="order-msg">
          <div class="order-msg-title">订单信息</div>
          <div class="order-msg2">
            <div>订单编号: {{ detailInfo.orderid }}</div>
            <div>创建时间: {{ detailInfo.createtime }}</div>
            <div>付款时间: {{ detailInfo.createtime }}</div>
          </div>
        </div>
        <div class="order-car">
          <div class="order-table-header">
            <div>商品</div>
            <div>商品属性</div>
            <div>状态</div>
            <div>单价</div>
            <div>数量</div>
          </div>
          <div class="order-table-body">
            <div class="order-content1">
              <img :src="detailInfo.img" alt="" />
              <div @click="toDetail(detailInfo.cart[0].goods_dbid)">
                {{detailInfo.cart && detailInfo.cart[0].goods_name }}
              </div>
            </div>
            <div class="order-content2">
              <div>
               
              </div>
            </div>
            <div class="order-content3">
               <div v-if="detailInfo.paystatus == 1">
                  <div v-if="detailInfo.orderstatus == 2">待发货</div>
                  <div v-if="detailInfo.orderstatus == 4">待收货</div>
                  <!-- <view v-if="item.orderstatus == 2">待发货</view> -->
                </div>
                <div v-if="detailInfo.paystatus == 0">待付款</div>
            </div>
            <div class="order-content4">{{detailInfo.cart && detailInfo.cart[0].price }}</div>
            <div class="order-content5">{{detailInfo.cart && detailInfo.cart[0].goods_num }}</div>
          </div>
        </div>

        <div class="order-price">
          <div class="order-price1">
            <div>商品总价：</div>
            <div>￥ {{ detailInfo.cart && (detailInfo.cart[0].price * detailInfo.cart[0].goods_num).toFixed(2) }}</div>
          </div>
          <div class="order-price1">
            <div>运费：</div>
            <div>￥0</div>
          </div>
            <div class="order-price2">
            <div>实付款：</div>
            <div><span>￥{{ detailInfo.cart && (detailInfo.cart[0].price * detailInfo.cart[0].goods_num).toFixed(2) }}</span> 元</div>
          </div>
        </div>
      </div>
    </div>

    
  </div>
</template>

<script>
import { http } from "../../../request/http";
export default {
  name: "order",
  data() {
    return {
      detailInfo: {}, // 订单详情
    };
  },
  mounted() {
    this.getOrderList();
  },
  methods: {
    getOrderList() {
      this.loading = true;
      http("order.php", {
        fun: "getShopOrder",
        orderid: this.$route.query.orderid,
      }).then((res) => {
        if (res.code == 0) {
          this.detailInfo = res.data[0];
        }
      });
    },
    toDetail(id) {
      this.$router.push('/detail?id=' + id)
    }
  },
};
</script>

<style scoped lang="scss">
@import "../../scss/index.scss";

.order {
  @include body;
  .main {
    background: #fff;
    padding: 10px;
    position: relative;
    margin-top: 40px;
    .order-title {
      border: 1px solid #aec7e5;
      display: inline-block;
      padding: 0 20px;
      border-bottom: 1px solid #fff;
      position: absolute;
      height: 20px;
      background: #fff;
      left: 30px;
    }
    .order-info {
      border: 1px solid #aec7e5;
      margin-top: 20px;
      padding: 20px;
      font-size: 13px;
      .order-delivery {
        border-bottom: 1px solid #d1d7dc;
        .order-cell {
          display: flex;
          margin-bottom: 20px;
          div {
            &:first-child {
              margin-right: 20px;
              font-weight: bold;
            }
          }
        }
      }
      .order-msg {
        margin-top: 10px;
        display: flex;
        .order-msg-title {
          font-weight: bold;
        }
        .order-msg2 {
          margin-top: 20px;
          margin-left: 20px;
          display: flex;
          flex-wrap: wrap;
          div {
            margin-right: 100px;
          }
        }
      }
      .order-car {
        border: 1px solid #ddd;
        margin-top: 20px;
        .order-table-header {
          background: #e8f2ff;
          display: flex;
          width: 80%;
          padding: 10px 0;
          div {
            &:nth-child(1) {
              width: 30%;
              text-align: center;
            }
            &:nth-child(2) {
              width: 20%;
              text-align: center;
            }
            &:nth-child(3) {
              width: 20%;
              text-align: center;
            }
            &:nth-child(4) {
              width: 20%;
              text-align: center;
            }
            &:nth-child(5) {
              flex: 1;
              text-align: center;
            }
          }
        }
      }
      .order-table-body {
        display: flex;
        width: 80%;
        .order-content1 {
          width: 30%;
          border: 1px solid #ddd;
          padding: 10px 0;
          display: flex;
          img {
            width: 50px;
            height: 50px;
            margin-left: 5px;
          }
          div {
            color: #36c;
            cursor: pointer;
            margin-left: 10px;
            &:hover {
              text-decoration: underline;
            }
          }
        }
        .order-content2 {
          width: 20%;
          text-align: center;
          border: 1px solid #ddd;
          padding: 10px 0;
        }
        .order-content3 {
          width: 20%;
          text-align: center;
          border: 1px solid #ddd;
          padding: 10px 0;
        }
        .order-content4 {
          width: 20%;
          text-align: center;
          border: 1px solid #ddd;
          padding: 10px 0;
        }
        .order-content5 {
          flex: 1;
          text-align: center;
          border: 1px solid #ddd;
          padding: 10px 0;
        }
      }

      .order-price {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        margin-top: 40px;
        .order-price1 {
          display: flex;
          margin-bottom: 5px;
          div {
            &:last-child {
              width: 130px;
              text-align: right;
            }
          }
        }
        .order-price2 {
          display: flex;
          align-items: center;
          div {
            &:last-child {
              width: 130px;
              text-align: right;
              span {
                color: #f50;
                font-weight: bold;
                font-size: 18px;
              }
            }
          }
        }
      }
    }
  }
}
</style>
